<template>
  <v-container class="my-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col
        v-if="!$vuetify.breakpoint.mobile"
        cols="12"
        sm="3"
        class="my-0 py-0 px-2"
      >
        <v-card
          :disabled="isloading"
          class="pa-2 my-0 mx-2 rounded-lg dborder"
          flat
          style="min-width:20vw"
        >
          <template v-if="$user.getCurrentUser().role.id === 4">
            <div
              @click="tab = 0"
              class="mb-1 rounded d-flex align-center px-3 font-weight-medium text-uppercase"
              :class="
                tab === 0 ? 'yellow darken-3 white--text' : 'primary--text'
              "
              style="height:36px; cursor:pointer"
              :style="tab === 0 ? '' : 'background-color: rgb(242, 247, 253);'"
            >
              {{ $t("APPOINTMENTS.TABS.upcoming") }}
            </div>
            <div
              @click="tab = 1"
              class="mb-1 rounded d-flex align-center px-3 font-weight-medium text-uppercase"
              :class="
                tab === 1 ? 'yellow darken-3 white--text' : 'primary--text'
              "
              style="height:36px; cursor:pointer"
              :style="tab === 1 ? '' : 'background-color: rgb(242, 247, 253);'"
            >
              {{ $t("APPOINTMENTS.TABS.previous") }}
            </div>

            <div class="d-flex flex-column">
              <div class="primary--text mt-3 text-headline font-weight-bold">
                {{ $t("APPOINTMENTS.TABS.filter") }}
              </div>
              <div style="min-width: fit-content" class=""></div>
              <v-chip-group column :key="tab" v-model="category">
                <v-chip
                  small
                  color="#d2e1f2"
                  class="primary--text"
                  active-class="white--text yellow darken-3"
                  filter
                  v-for="item in departments"
                  :key="item.id"
                >
                  <v-avatar
                    style="
                    min-width: 18px !important;
                    min-height: 18px !important;
                  "
                    height="18px"
                    width="18px"
                    max-height="18px"
                    max-width="18px"
                    color="white"
                    left
                  >
                    <v-img
                      contain
                      height="10"
                      v-if="item.avatar"
                      :src="asseturl + item.avatar.private_hash"
                    ></v-img>
                    <div
                      v-else
                      style="text-transform: uppercase; width: 100%"
                      class="primary--text font-size-h4"
                    >
                      {{ item.name.charAt(0) }}
                    </div>
                  </v-avatar>
                  {{ $util.getTranslation(item).name }}
                </v-chip>
              </v-chip-group>
            </div>
          </template>
          <template v-else-if="$user.getCurrentUser().role.id === 3">
            <div
              @click="tab = 0"
              class="mb-1 rounded d-flex align-center px-3 font-weight-medium text-uppercase"
              :class="
                tab === 0 ? 'yellow darken-3 white--text' : 'primary--text'
              "
              style="height:36px; cursor:pointer"
              :style="tab !== 0 ? '' : 'background-color: rgb(242, 247, 253);'"
            >
              {{ $t("APPOINTMENTS.TABS.upcoming") }}
            </div>
            <div
              @click="tab = 1"
              class="mb-1 rounded d-flex align-center px-3 font-weight-medium text-uppercase"
              :class="
                tab === 1 ? 'yellow darken-3 white--text' : 'primary--text'
              "
              style="height:36px; cursor:pointer"
              :style="tab !== 1 ? '' : 'background-color: rgb(242, 247, 253);'"
            >
              {{ $t("APPOINTMENTS.TABS.calendar") }}
            </div>
            <div
              @click="tab = 2"
              class="mb-1 rounded d-flex align-center px-3 font-weight-medium text-uppercase"
              :class="
                tab === 2 ? 'yellow darken-3 white--text' : 'primary--text'
              "
              style="height:36px; cursor:pointer"
              :style="tab !== 2 ? '' : 'background-color: rgb(242, 247, 253);'"
            >
              {{ $t("APPOINTMENTS.TABS.previous") }}
            </div>
          </template>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        :class="
          $vuetify.breakpoint.mobile
            ? 'ma-0 pa-0 overflow-x-hidden'
            : 'my-0 py-0'
        "
      >
        <v-expand-transition>
          <v-card
            :disabled="isloading"
            class="transparent pa-0 ma-0"
            flat
            v-show="showcontroles && $vuetify.breakpoint.mobile"
          >
            <template v-if="$user.getCurrentUser().role.id === 4">
              <!-- mobile tabs -->
              <v-btn-toggle
                v-model="tab"
                mandatory
                dense
                style="width: 100%"
                class="mx-0 blue primary--text rounded-lg"
              >
                <v-btn
                  active-class="activebtn"
                  width="50%"
                  class="primary--text"
                >
                  {{ $t("APPOINTMENTS.TABS.upcoming") }}
                </v-btn>
                <v-btn
                  active-class="activebtn"
                  width="50%"
                  class="primary--text"
                >
                  {{ $t("APPOINTMENTS.TABS.previous") }}
                </v-btn>
              </v-btn-toggle>

              <!-- mobile filter - user -->
              <div class="d-flex align-center">
                <div style="min-width: fit-content" class="pushx primary--text">
                  {{ $t("APPOINTMENTS.TABS.filter") }}
                </div>
                <v-chip-group :key="tab" v-model="category">
                  <v-chip
                    small
                    color="#d2e1f2"
                    class="primary--text"
                    active-class="white--text yellow darken-3"
                    filter
                    v-for="item in departments"
                    :key="item.id"
                  >
                    <v-avatar
                      style="
                    min-width: 18px !important;
                    min-height: 18px !important;
                  "
                      height="18px"
                      width="18px"
                      max-height="18px"
                      max-width="18px"
                      color="white"
                      left
                    >
                      <v-img
                        contain
                        height="10"
                        v-if="item.avatar"
                        :src="asseturl + item.avatar.private_hash"
                      ></v-img>
                      <div
                        v-else
                        style="text-transform: uppercase; width: 100%"
                        class="primary--text font-size-h4"
                      >
                        {{ item.name.charAt(0) }}
                      </div>
                    </v-avatar>
                    {{ $util.getTranslation(item).name }}
                  </v-chip>
                </v-chip-group>
              </div>
            </template>
            <template v-else-if="$user.getCurrentUser().role.id === 3">
              <!-- mobile filter - doctor -->

              <v-btn-toggle
                v-model="tab"
                mandatory
                dense
                style="width: 100%"
                class="mx-0 blue primary--text rounded-lg mb-2"
              >
                <v-btn
                  active-class="activebtn"
                  width="33.3%"
                  class="primary--text"
                >
                  {{ $t("APPOINTMENTS.TABS.upcoming") }}
                </v-btn>
                <v-btn
                  active-class="activebtn"
                  width="33.3%"
                  class="primary--text"
                >
                  {{ $t("APPOINTMENTS.TABS.calendar") }}
                </v-btn>
                <v-btn
                  active-class="activebtn"
                  width="33.3%"
                  class="primary--text"
                >
                  {{ $t("APPOINTMENTS.TABS.previous") }}
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-card>
        </v-expand-transition>
        <v-date-picker
          v-if="tab === 1 && $user.getCurrentUser().role.id === 3"
          v-model="selectedDate"
          color="primary"
          full-width
          no-title
          show-week
          :events="bookedDates"
          @update:picker-date="monthChange"
          event-color="green lighten-1"
        ></v-date-picker>
        <div
          v-if="isloadingsection"
          class="d-flex flex-column justify-center align-center"
          style="height: calc(100vh - 220px)"
          :style="!$vuetify.breakpoint.mobile ? 'min-width:40vw' : ''"
        >
          <v-progress-circular indeterminate color="primary" size="40" />
        </div>
        <v-virtual-scroll
          v-else-if="filteredlist.length > 0"
          :bench="20"
          :items="filteredlist"
          :height="
            $vuetify.breakpoint.mobile &&
            tab === 1 &&
            $user.getCurrentUser().role.id === 3
              ? 'calc(100vh - 120px - 290px)'
              : $vuetify.breakpoint.mobile
              ? 'calc(100vh - 120px'
              : !$vuetify.breakpoint.mobile &&
                tab === 1 &&
                $user.getCurrentUser().role.id === 3
              ? 'calc(100vh - 130px - 290px)'
              : 'calc(100vh - 130px)'
          "
          item-height="106"
          class="bg-transparent hidescroll"
          @scroll="onscroll"
        >
          <template v-slot:default="{ item }">
            <div
              class="mb-1 listitem rounded-lg"
              v-intersect="onIntersect(item.id)"
            >
              <v-list-item style="min-height: 96px" :key="item.localid">
                <v-list-item-avatar size="47" color="#F2F7FD">
                  <template v-if="$user.getCurrentUser().role.id === 4">
                    <v-img
                      v-if="
                        item.doctor.doctor_id &&
                          item.doctor.doctor_id.avatar != null
                      "
                      height="47"
                      contain
                      :src="
                        asseturl + item.doctor.doctor_id.avatar.private_hash
                      "
                    ></v-img>
                    <div
                      v-else-if="item.doctor.doctor_id"
                      style="text-transform: uppercase; width: 100%"
                      class="primary--text font-size-h4"
                    >
                      {{ item.doctor.doctor_id.first_name.charAt(0) }}
                    </div>
                    <div
                      v-else
                      style="text-transform: uppercase; width: 100%"
                      class="primary--text font-size-h4"
                    >
                      D
                    </div>
                  </template>
                  <template v-else>
                    <v-img
                      v-if="item.medical_file.owner.avatar != null"
                      height="47"
                      contain
                      :src="
                        asseturl + item.medical_file.owner.avatar.private_hash
                      "
                    ></v-img>
                    <div
                      v-else
                      style="text-transform: uppercase; width: 100%"
                      class="primary--text font-size-h4"
                    >
                      {{ item.medical_file.owner.first_name.charAt(0) }}
                    </div>
                  </template>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      item.type == "video"
                        ? $t("APPOINTMENTS.APP.vidwith")
                        : item.type == "clinic"
                        ? $t("APPOINTMENTS.APP.clinicwith")
                        : ""
                    }}
                    <span v-if="$user.getCurrentUser().role.id === 4">
                      Dr
                      <span v-if="item.doctor.doctor_id"
                        >{{ item.doctor.doctor_id.first_name }}
                        {{ item.doctor.doctor_id.last_name }}</span
                      ><span v-else>Doctory Doctor</span></span
                    >
                    <span v-else>
                      {{ item.medical_file.owner.first_name }}
                      {{ item.medical_file.owner.last_name }}
                    </span></v-list-item-title
                  >
                  <v-list-item-subtitle class="my-2">{{
                    item.question
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    >{{
                      $util.processTime(item.date_time)
                        | moment(
                          "timezone",
                          $util.getTimezone(),
                          "ddd DD/MM/YYYY hh:mm A"
                        )
                    }}
                    {{
                      $util.processTime(item.date_time)
                        | moment("timezone", $util.getTimezone(), "from", "now")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <template v-if="actiontype(item).includes('mf')">
                    <router-link
                      :to="'/medical_file/' + item.medical_file.id"
                      v-slot="{ href, navigate }"
                    >
                      <v-btn icon :href="href" @click="navigate">
                        <unicon
                          name="file-medical-alt"
                          fill="#0971CE"
                          height="20"
                        ></unicon>
                      </v-btn>
                    </router-link>
                  </template>
                  <template v-if="actiontype(item).includes('trash')">
                    <v-btn
                      icon
                      elevation="0"
                      @click="
                        todelete = item.id;
                        deletedialg = true;
                      "
                    >
                      <unicon
                        style="margin-right: auto; margin-left: auto"
                        name="trash-alt"
                        fill="#f64e60"
                        height="20"
                      ></unicon>
                    </v-btn>
                  </template>
                  <template v-if="actiontype(item).includes('video')">
                    <router-link
                      :to="'/appointment/' + item.id"
                      v-slot="{ navigate }"
                    >
                      <v-btn
                        small
                        fab
                        elevation="0"
                        color="rgba(165, 203, 239, 0.20)"
                        @click="navigate"
                      >
                        <unicon
                          style="margin-right: auto; margin-left: auto"
                          name="video"
                          fill="#0971CE"
                          height="20"
                        ></unicon>
                      </v-btn>
                    </router-link>
                  </template>
                  <template v-if="actiontype(item).includes('play')">
                    <router-link
                      :to="'/appointment/' + item.id"
                      v-slot="{ navigate }"
                    >
                      <v-btn
                        small
                        fab
                        elevation="0"
                        color="rgba(165, 203, 239, 0.20)"
                        @click="navigate"
                      >
                        <unicon
                          style="margin-right: auto; margin-left: auto"
                          name="video"
                          fill="#0971CE"
                          height="20"
                        ></unicon>
                      </v-btn>
                    </router-link>
                  </template>
                  <template v-if="actiontype(item).includes('report')">
                    <v-btn
                      elevation="0"
                      class="mx-1 white--text"
                      :key="item.id"
                      rounded
                      :small="$vuetify.breakpoint.mobile"
                      color="teal"
                      :fab="$vuetify.breakpoint.mobile"
                      @click="getsummery(item)"
                    >
                      <unicon
                        style="margin-right: auto;margin-left: auto;"
                        name="file-check-alt"
                        fill="#FFFFFF"
                        height="20"
                      ></unicon
                      ><span v-if="!$vuetify.breakpoint.mobile">Report</span>
                    </v-btn>
                  </template>
                </v-list-item-action>
              </v-list-item>
            </div>
          </template>
        </v-virtual-scroll>
        <div
          v-else
          :height="
            $vuetify.breakpoint.mobile &&
            tab === 1 &&
            $user.getCurrentUser().role.id === 3
              ? 'calc(100vh - 120px - 290px)'
              : $vuetify.breakpoint.mobile
              ? 'calc(100vh - 120px'
              : !$vuetify.breakpoint.mobile &&
                tab === 1 &&
                $user.getCurrentUser().role.id === 3
              ? 'calc(100vh - 130px - 290px)'
              : 'calc(100vh - 130px)'
          "
          class="mt-5"
        >
          <lottie-animation
            style="height:70px"
            :animationData="require('@/assets/animation/wow.json')"
            :loop="true"
          />
          <div class="text-muted text-center text-h6">
            {{ $t("GENERAL.empty") }}
          </div>
        </div>

        <v-scroll-y-reverse-transition>
          <div
            class="d-flex justify-center align-center"
            style="width:100%;max-height:40px"
          >
            <v-btn
              v-show="isloading && !isloadingsection"
              style="z-index: 1;position: fixed;bottom: calc(env(Safe-area-inset-bottom, 40px) + 30px);"
              rounded
              elevation="0"
              color="primary"
              dark
            >
              {{ $t("GENERAL.loading") }}
            </v-btn>
          </div>
        </v-scroll-y-reverse-transition>
      </v-col>
    </v-row>
    <v-scroll-y-reverse-transition>
      <v-btn
        v-if="$user.getCurrentUser().role.id === 4"
        @click="$refs.newApp.open()"
        v-show="showcontroles"
        :style="$root.lang === 'ar' ? 'left: 10px;' : 'right: 10px;'"
        style="
          z-index: 1;
          position: fixed;
          bottom: calc(env(Safe-area-inset-bottom, 40px) + 30px);
        "
        fab
        elevation="0"
        color="primary"
        dark
      >
        <v-icon ref="addicon" class="" color="white">mdi-plus</v-icon>
      </v-btn>
    </v-scroll-y-reverse-transition>

    <v-dialog v-model="deletedialg" max-width="290" persistent>
      <v-card :loading="isdeleting" :disabled="isdeleting" class="rounded-lg">
        <v-card-title class="text-h5">
          {{ $t("QUES.delete.warn.title") }}
        </v-card-title>

        <v-card-text style="font-size: 1.1rem">
          {{ $t("QUES.delete.warn.body") }}
        </v-card-text>

        <v-card-actions style="border-top: solid 1px lightgray" class="pa-0">
          <v-row style="width: 100%" class="pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <v-btn
                color="primary darken-1"
                text
                class="elevation-0"
                block
                large
                @click="
                  todelete = null;
                  deletedialg = false;
                "
              >
                {{ $t("GENERAL.cancel") }}
              </v-btn>
            </v-col>
            <v-col
              class="pa-0 ma-0"
              :style="
                $root.lang === 'ar'
                  ? 'border-right: solid 1px lightgray'
                  : 'border-left: solid 1px lightgray'
              "
            >
              <v-btn
                color="red darken-1"
                class="elevation-0"
                text
                block
                large
                @click="deleteItem()"
              >
                {{ $t("GENERAL.delete") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-scroll-y-reverse-transition>
      <v-btn
        v-if="$user.getCurrentUser().role.id === 4"
        @click="openAddAppointment()"
        v-show="showcontroles"
        :style="$root.lang === 'ar' ? 'left: 10px;' : 'right: 10px;'"
        style="
          z-index: 1;
          position: fixed;
          bottom: calc(env(Safe-area-inset-bottom, 40px) + 30px);
        "
        fab
        elevation="0"
        color="primary"
        dark
      >
        <v-icon ref="addicon" class="" color="white">mdi-plus</v-icon>
      </v-btn>
    </v-scroll-y-reverse-transition>

    <NewAppointment @updateupcoming="addtoupcoming" ref="newApp" />
    <AppReport ref="report" />
  </v-container>
</template>

<script>
import NewAppointment from "./comp/NewAppointment.vue";
import LottieAnimation from "lottie-web-vue";
import AppReport from "./comp/Report.vue";
export default {
  name: "appiontments",
  components: { NewAppointment, AppReport, LottieAnimation },
  data() {
    return {
      items: [],
      asseturl: process.env.VUE_APP_api_url + "assets/",
      options: this.$app.getOptions(),
      loadnewpageat: -1,
      page: 1,
      limit: 0,
      isloading: false,
      isloadingsection: true,
      category: null,
      tab: 0,
      showcontroles: true,
      offsettop: 0,
      showmodal: false,
      todelete: null,
      deletedialg: false,
      isdeleting: false,
      selected: null,
      selectedDate: new Date().toISOString().substr(0, 10),
      bookedDates: [],
      checkupcomingapps: null
    };
  },
  beforeMount() {},
  created() {
    // this.getItems();
  },
  beforeDestroy() {
    console.log("before distory and removing interval");
    clearInterval(this.checkupcomingapps);
  },
  computed: {
    departments() {
      return this.$department.data.departments.data.filter(department => {
        return department.doctors.length > 0;
      });
    },
    filteredlist() {
      if (this.tab === 0) {
        if (this.$user.getCurrentUser().role.id === 4) {
          if (this.category === null || this.category == undefined) {
            // // console.log("category is empty return all items");
            return this.items.filter(item => {
              return item.status === "pending" || item.status === "progress";
            });
          } else {
            /* // console.log(
            "category is",
            this.category,
            "depart is",
            this.$department.data.departments.data[this.category]
          ); */
            // this.loadnewcategorypage();
            return this.items.filter(item => {
              return (
                item.doctor.department.id === this.departments[this.category].id
              );
            });
          }
        } else {
          return this.items.filter(item => {
            return item.status === "pending" || item.status === "progress";
          });
        }
      } else if (this.tab === 1) {
        if (this.$user.getCurrentUser().role.id === 4) {
          if (this.category === null || this.category == undefined) {
            // // console.log("category is empty return all items");
            return this.items.filter(item => {
              return item.status !== "progress" && item.status !== "pending";
            });
          } else {
            /* // console.log(
            "category is",
            this.category,
            "depart is",
            this.$department.data.departments.data[this.category]
          ); */
            // this.loadnewcategorypage();
            return this.items.filter(item => {
              return (
                item.doctor.department.id === this.departments[this.category].id
              );
            });
          }
        } else
          return this.items.filter(item => {
            console.log(
              this.$util
                .day(item.date_time)
                .format("YYYY-MM-DD")
                .toString()
            );
            return this.$util
              .day(item.date_time)
              .format("YYYY-MM-DD")
              .toString()
              .includes(this.selectedDate.toString());
          });
      } else {
        return this.items;
      }
    }
  },
  watch: {
    offsettop(news, olds) {
      if (news <= 0) {
        this.showcontroles = true;
      } else if (olds < news) this.showcontroles = false;
      else this.showcontroles = true;
    },
    category() {
      // // console.log("------- firing from category")
      this.updateOptions();
      this.getItems();
    },
    selectedDate() {
      this.updateOptions();
      this.getItems();
    },
    tab() {
      this.category = null;
      this.isloadingsection = true;

      // // console.log("------- firing from tab")
      this.updateOptions();
      this.getItems();
    }
  },
  mounted() {
    this.$root.title = this.$t("MENU.appointment");
    this.$root.currentpage = this.$route.name;
    this.updateOptions();
    // // console.log("------- firing from mounted")
    this.getItems();
    this.checkupcomingapps = setInterval(() => {
      console.log("in interval");
      this.items
        .filter(item => {
          return item.status === "pending";
        })
        .forEach(item => {
          console.log(
            "id",
            item.id,
            "difference",
            parseInt(
              this.$util
                .day(this.$util.processTime(item.date_time))
                .format("x")
                .toString()
            ) - parseInt(Date.now())
          );
          if (
            parseInt(
              this.$util
                .day(this.$util.processTime(item.date_time))
                .format("x")
                .toString()
            ) -
              parseInt(Date.now()) <
            300000
          ) {
            item.status = "progress";
          }
        });
    }, 60000);
  },
  methods: {
    addtoupcoming(e) {
      console.log("add to upcoming", e);
      this.items.push(e);
    },
    openAddAppointment() {
      this.$refs.newApp.open();
    },
    actiontype(item) {
      if (this.$user.getCurrentUser().role.id === 3) {
        if (item.status === "pending") {
          return "mf-trash";
        }
        if (item.status === "progress") {
          if (item.type === "video") {
            return "video";
          } else return "play";
        }
        return "report";
      } else if (this.$user.getCurrentUser().role.id === 4) {
        if (item.status === "pending") {
          return "trash";
        }
        if (item.status === "progress") {
          if (item.type === "video") {
            return "video";
          }
          if (item.type === "clinic") {
            console.log(item.id, item.status, item.type);
            return "nothing";
          }
        }
      }
      return "report";
    },
    monthChange(e) {
      console.log("month updated", e);
      this.$app
        .getDoctorsAppointmentByDate(this.$doctor.getCurrentDoctor().id, e)
        .then(res => {
          console.log("res", res.data.data);
          this.bookedDates = res.data.data.map(item => {
            return this.$util.day(item.date_time).format("YYYY-MM-DD");
          });
          console.log("filtered", this.bookedDates);
        });
    },
    updateOptions() {
      this.options = this.$app.resetOptions();
      // // console.log("update options");
      /*
      
          &filter[date_time][lt]=now&sort=-date_time&filter[status][neq]=progress
      */
      if (this.$user.getCurrentUser().role.id === 3) {
        if (this.tab === 0) {
          this.options.filter.doctor = {
            operator: "eq",
            value: this.$doctor.getCurrentDoctor().id
          };
          this.options.filter.status = {
            operator: "in",
            value: "progress,pending"
          };
          this.options.sort = "date_time";
        } else if (this.tab === 1) {
          this.options.filter.doctor = {
            operator: "eq",
            value: this.$doctor.getCurrentDoctor().id
          };
          this.options.filter.date_time = {
            operator: "contains",
            value: this.selectedDate
          };
          this.options.sort = "-date_time";
        } else if (this.tab === 2) {
          this.options.filter.doctor = {
            operator: "eq",
            value: this.$doctor.getCurrentDoctor().id
          };
          this.options.filter.status = { operator: "neq", value: "progress" };
          this.options.filter.date_time = { operator: "lt", value: "now" };
          this.options.sort = "-date_time";
        }
      } else if (this.$user.getCurrentUser().role.id === 4) {
        if (this.tab === 0) {
          this.options.filter["medical_file.owner"] = {
            operator: "eq",
            value: this.$user.getCurrentUser().id
          };
          this.options.filter.status = {
            operator: "in",
            value: "progress,pending"
          };
          this.options.sort = "date_time";
        } else {
          this.options.filter["medical_file.owner"] = {
            operator: "eq",
            value: this.$user.getCurrentUser().id
          };
          this.options.filter.status = { operator: "neq", value: "progress" };
          this.options.filter.date_time = { operator: "lt", value: "now" };
          this.options.sort = "-date_time";
        }
        if (
          this.category !== null &&
          this.category !== undefined &&
          this.category >= 0
        ) {
          this.options.filter["doctor.department"] = {
            operator: "eq",
            value: this.departments[this.category].id
          };
        }
      }
      // // console.log("updated options", this.options);
    },
    async getItems() {
      if (!this.isloading) {
        this.isloading = true;
        // console.log("in get items");

        // // console.log("loading new page", this.page);
        await this.$app.getItems(this.options).then(data => {
          // console.log("new page data", data);
          this.limit = data.data.meta.page_count;

          // // console.log("new items", this.items);
          if (data.data.data.length !== 0) {
            // console.log("data is grater than 0", this.filteredlist);
            data.data.data.map(item => {
              item.localid = item.id;
              return item;
            });
            this.items = this.$util.merge(data.data.data, this.filteredlist);
            this.loadnewpageat =
              data.data.data.length > 10
                ? this.items[this.items.length - 10].id
                : this.items[this.items.length - 1].id;
          } else {
            this.loadnewpageat = -1;
          }
          this.options.page++;
          this.isloading = false;
          this.isloadingsection
            ? (this.isloadingsection = !this.isloadingsection)
            : "";
        });
      }
    },
    async deleteItem() {
      console.log("item to be deleted", this.todelete);
      this.isdeleting = true;
      await this.$app.deleteItem(this.todelete).then(res => {
        if (res.status === 204) {
          // // console.log("delete successful, remove from list", res);
          this.isdeleting = false;
          this.deletedialg = false;
          let index = this.items.findIndex(item => {
            return this.todelete === item.id;
          });
          this.items.splice(index, 1);
          this.todelete = null;
        } else {
          // // console.log("delete error");
          this.isdeleting = false;
        }
      });
    },
    getsummery(item) {
      console.log("get summery", item);
      this.$refs.report.doOpen(item);
    },
    onscroll(e) {
      this.offsettop = e.target.scrollTop;
    },
    async onIntersect(id) {
      /* // console.log(
        "item shown is",
        id,
        "threshold is",
        this.loadnewpageat,
        "limit",
        this.limit,
        "page",
        this.options.page
      ); */
      if (
        id === this.loadnewpageat &&
        this.loadnewpageat > 0 &&
        this.options.page <= this.limit
      ) {
        // // console.log("------- firing from intercect")
        this.getItems();
      }
    }
  }
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-application--wrap {
  min-height: 0px !important;
}
.activebtn {
  border-style: solid;
  border-color: #d2e1f2 !important;
  background: white !important;
  border-width: 4px !important;
  color: #0971ce !important;
}
</style>
<style scoped>
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) >>> .v-btn.v-btn {
  border-color: #d2e1f2 !important;
  border-width: 0px;
}

.theme--light >>> .v-picker__body {
  background: rgb(238, 240, 248);
}

.v-btn:before {
  background-color: transparent !important;
}
.theme--light >>> .v-navigation-drawer__border {
  background-color: transparent !important;
}
</style>
