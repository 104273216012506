<template>
  <div>
    <v-navigation-drawer
      v-model="open"
      app
      temporary
      hide-overlay
      class="transparent elevation-0"
      :width="$vuetify.breakpoint.mobile ? '100vw' : '40vw'"
      style="border-radius: 0; border: none; padding: 10px"
      :right="$root.lang != 'ar'"
    >
      <v-card
        v-if="item !== null"
        style="height: calc(100vh - 20px); max-height: calc(100vh - 20px)"
        flat
        class="dborder rounded-lg"
      >
        <v-toolbar
          flat
          color="rgb(238, 240, 248)"
          height="60"
          style="border-radius: 8px 8px 0px 0px"
        >
          <v-btn icon dark color="primary" @click="doClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-primary">
            {{ $t("MF.summery.title") }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            small
            elevation="0"
            @click="reportIssue()"
            rounded
            height="40px"
            color="rgba(165, 203, 239, 0.20)"
          >
            <unicon
              style="margin-right: auto; margin-left: auto"
              name="exclamation-triangle"
              fill="#0971CE"
              height="20"
            ></unicon>
            <span style="color: #0971ce">{{
              $t("MF.summery.issue.title")
            }}</span>
          </v-btn>
        </v-toolbar>
        <v-card-text
          style="max-height: calc(100vh - 100px)"
          class="overflow-y-auto hidescroll"
        >
          <div class="text-h6 primary--text text-capitalize mb-2">
            {{ $t("MF.summery.app", { type: item.type }) }}
          </div>
          <v-row class="ma-0 pa-0" style="gap: 8px">
            <v-col class="ma-0 pa-0">
              <v-list-item class="dborder rounded-lg">
                <v-list-item-avatar color="#F2F7FD">
                  <v-img
                    v-if="item.medical_file.owner.avatar != null"
                    height="47"
                    contain
                    :src="
                      $root.asseturl +
                        item.medical_file.owner.avatar.private_hash
                    "
                  ></v-img>
                  <div
                    v-else
                    style="text-transform: uppercase; width: 100%"
                    class="primary--text font-size-h4"
                  >
                    {{ item.medical_file.owner.first_name.charAt(0) }}
                  </div>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.medical_file.owner.first_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="patientAttended === 1"
                    class="green--text"
                    ><v-icon color="green" small>mdi-check-circle</v-icon>
                    {{ $t("MF.summery.show") }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else-if="patientAttended === -1"
                    class="red--text"
                    ><v-icon color="red" small>mdi-close-circle</v-icon>
                    {{ $t("MF.summery.noshow") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col class="ma-0 pa-0">
              <v-list-item class="dborder rounded-lg">
                <v-list-item-avatar color="#F2F7FD">
                  <v-img
                    v-if="
                      item.doctor.doctor_id &&
                        item.doctor.doctor_id.avatar != null
                    "
                    height="47"
                    contain
                    :src="
                      $root.asseturl + item.doctor.doctor_id.avatar.private_hash
                    "
                  ></v-img>
                  <div
                    v-else-if="item.doctor.doctor_id"
                    style="text-transform: uppercase; width: 100%"
                    class="primary--text font-size-h4"
                  >
                    {{ item.doctor.doctor_id.first_name.charAt(0) }}
                  </div>
                  <div
                    v-else
                    style="text-transform: uppercase; width: 100%"
                    class="primary--text font-size-h4"
                  >
                    D
                  </div>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      $t("GENERAL.doctor", {
                        name: item.doctor.doctor_id.first_name
                      })
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="doctorAttended === 1"
                    class="green--text"
                    ><v-icon color="green" small>mdi-check-circle</v-icon>
                    {{ $t("MF.summery.show") }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else-if="doctorAttended === -1"
                    class="red--text"
                    ><v-icon color="red" small>mdi-close-circle</v-icon>
                    {{ $t("MF.summery.noshow") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <div class="mt-2" v-if="overview">
            <v-row class="ma-0 pa-0" style="gap: 8px">
              <v-col v-for="item in overview" :key="item.id" class="ma-0 pa-0">
                <v-list-item class="rounded-lg dborder">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{
                        $util.processTime(item.value)
                          | moment(
                            "timezone",
                            $util.getTimezone(),
                            "ddd DD/MM/YYYY hh:mm A"
                          )
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </div>
          <div class="mt-2">
            <div class="text-h6 primary--text text-capitalize mb-2">
              {{ $t("MF.summery.feedback.title") }}
            </div>
            <div
              v-if="feedback === null"
              class="d-flex justify-center align-center"
            >
              <v-progress-circular indeterminate color="blue" />
            </div>
            <div
              v-else-if="
                feedback === 'notavailable' &&
                  $user.getCurrentUser().role.id === 4
              "
              class=""
            >
              <template v-if="item.type !== 'video' || patientAttended !== -1">
                {{ $t("MF.summery.feedback.q") }}
                <div class="d-flex justify-center align-center">
                  <v-rating
                    background-color="grey lighten-2"
                    color="warning"
                    hover
                    size="35"
                    length="5"
                    v-model="feedbackForm.rating"
                    class="mb-2"
                  ></v-rating>
                </div>
                <v-textarea
                  outlined
                  dense
                  auto-grow
                  hide-details="auto"
                  class="rounded-lg mb-2"
                  v-model="feedbackForm.comment"
                  rows="3"
                  :label="$t('MF.summery.feedback.details')"
                />
                <v-btn
                  @click="sendFeedback"
                  color="primary"
                  block
                  rounded
                  elevation="0"
                  :loading="postingfeedback"
                  :disabled="postingfeedback"
                  >{{ $t("MF.summery.feedback.send") }}</v-btn
                >
              </template>
            </div>
            <div v-else-if="feedback.id" class="d-flex">
              <v-avatar class="pushx" size="30" color="#F2F7FD">
                <v-img
                  v-if="feedback.owner && feedback.owner.avatar"
                  :src="$root.asseturl + feedback.owner.avatar.private_hash"
                />

                <div
                  v-else
                  style="text-transform: uppercase; width: 100%"
                  class="primary--text font-size-h4"
                >
                  {{ feedback.owner.first_name.charAt(0) }}
                </div>
              </v-avatar>
              <div class="d-flex flex-column">
                <div class="msg local green" style="">
                  <v-rating
                    background-color="white"
                    color="white"
                    readonly
                    size="18"
                    length="5"
                    class="pa-0 ma-0"
                    :value="feedback.rating"
                  ></v-rating>
                  <span style="font-size:1.1rem" class="px-2">{{
                    feedback.comment
                  }}</span>
                </div>
                <span
                  :class="$root.lang === 'ar' ? 'text-left' : 'text-right'"
                  >{{
                    $util.day(feedback.created_on).format("DD/MM/YYYY")
                  }}</span
                >
              </div>
            </div>
            <div v-else class="">{{ $t("MF.summery.feedback.notyet") }}</div>
          </div>
          <div class="mt-2">
            <div class="text-h6 primary--text text-capitalize mb-2">
              {{ $t("MF.summery.summery.title") }}
            </div>
            <div
              v-if="summery === null"
              class="d-flex justify-center align-center"
            >
              <v-progress-circular indeterminate color="blue" />
            </div>
            <div v-else-if="summery === 'notavailable'" class="">
              {{ $t("MF.summery.summery.nochange") }}
            </div>
            <div v-else>
              <template v-if="summery.notes.length > 0">
                <v-list-item
                  v-for="item in summery.notes"
                  :key="item.id"
                  class="listitem mb-2"
                >
                  <v-list-item-avatar size="40" color="#F2F7FD">
                    <v-img
                      height="20"
                      contain
                      :src="'/media/svg/icons/Doctory/group-700.svg'"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("MF.summery.summery.sections.Note")
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{ item.note_body }}</span></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-if="summery.vitals.length > 0">
                <v-list-item
                  v-for="item in summery.vitals"
                  :key="item.id"
                  class="listitem mb-2"
                >
                  <v-list-item-avatar size="40" color="#F2F7FD">
                    <v-img
                      height="20"
                      contain
                      :src="'/media/svg/icons/Doctory/icons-monitoring.svg'"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("MF.summery.summery.sections.Reading")
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{
                        item.vital_type.name
                      }}</span></v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    {{ item.reading }} <br />
                    {{ item.vital_type.suffix }}
                  </v-list-item-action>
                </v-list-item>
              </template>
              <template v-if="summery.history.length > 0">
                <v-list-item
                  v-for="item in summery.history"
                  :key="item.id"
                  class="listitem mb-2"
                >
                  <v-list-item-avatar size="40" color="#F2F7FD">
                    <v-img
                      height="15"
                      contain
                      :src="'/media/svg/icons/Doctory/icons-pc.svg'"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("MF.summery.summery.sections.history", {
                        type: $util.getTranslation(
                          $historyTypes.getlocalItems().find(i => {
                            return i.type === item.history_type.type;
                          })
                        ).name
                      })
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{ item.history_body }}</span></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-if="summery.diagnoses.length > 0">
                <v-list-item
                  v-for="item in summery.diagnoses"
                  :key="item.id"
                  class="listitem mb-2"
                >
                  <v-list-item-avatar size="40" color="#F2F7FD">
                    <v-img
                      height="20"
                      contain
                      :src="'/media/svg/icons/Doctory/icons-doctor.svg'"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("MF.summery.summery.sections.diagnoses", {
                        code: item.icd.Code
                      })
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{
                        item.icd.Description
                      }}</span></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-if="summery.prescriptions.length > 0">
                <v-list-item
                  v-for="item in summery.prescriptions"
                  :key="item.id"
                  class="listitem mb-2"
                >
                  <v-list-item-avatar size="40" color="#F2F7FD">
                    <v-img
                      height="20"
                      contain
                      :src="'/media/svg/icons/Doctory/icons-pills.svg'"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("MF.summery.summery.sections.pres")
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      v-for="med in item.medication"
                      :key="med.id"
                    >
                      {{ med.intake }} {{ med.type }} of {{ med.dosage }}
                      {{ med.medicine.name }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <v-dialog v-model="issueDialog" max-width="400" persistent>
      <v-card :loading="isreporting" :disabled="isreporting" class="rounded-lg">
        <v-card-title class="text-h5">
          {{ $t("MF.summery.issue.title") }}
        </v-card-title>

        <v-card-text style="font-size: 1.1rem">
          <div>{{ $t("MF.summery.issue.subtitle") }}</div>
          <v-chip-group column v-model="quickissue" multiple>
            <v-chip
              small
              color="#d2e1f2"
              class="primary--text"
              active-class="white--text yellow darken-3"
              filter
              v-for="(item, i) in $user.getCurrentUser().role.id === 4
                ? issueQuickAddPatient
                : issueQuickAddDoctor"
              :key="i"
            >
              {{ item }}
            </v-chip>
          </v-chip-group>
          <v-textarea
            outlined
            dense
            auto-grow
            hide-details="auto"
            class="rounded-lg mb-2"
            v-model="issueBody"
            rows="3"
            :placeholder="$t('MF.summery.issue.moredetails')"
          />
        </v-card-text>

        <v-card-actions style="border-top: solid 1px lightgray" class="pa-0">
          <v-row style="width: 100%" class="pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <v-btn
                color="red lighten-2"
                text
                class="elevation-0"
                block
                large
                @click="issueDialog = false"
              >
                {{ $t("GENERAL.cancel") }}
              </v-btn>
            </v-col>
            <v-col
              class="pa-0 ma-0"
              :style="
                $root.lang === 'ar'
                  ? 'border-right: solid 1px lightgray'
                  : 'border-left: solid 1px lightgray'
              "
            >
              <v-btn
                color="primary darken-1"
                class="elevation-0"
                text
                block
                large
                @click="sendIssue"
              >
                {{ $t("GENERAL.send") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AppReport",
  components: {},
  data() {
    return {
      open: false,
      item: null,
      doctorAttended: 0,
      patientAttended: 0,
      summery: null,
      feedback: null,
      feedbackForm: {},
      postingfeedback: false,
      issueDialog: false,
      isreporting: false,
      issueBody: "",
      quickissue: [],
      issueQuickAddPatient: [
        this.$t("MF.summery.issue.issuesp.video"),
        this.$t("MF.summery.issue.issuesp.nowshow"),
        this.$t("MF.summery.issue.issuesp.chat"),
        this.$t("MF.summery.issue.issuesp.mf")
      ],
      issueQuickAddDoctor: [
        this.$t("MF.summery.issue.issuesd.video"),
        this.$t("MF.summery.issue.issuesd.nowshow"),
        this.$t("MF.summery.issue.issuesd.chat"),
        this.$t("MF.summery.issue.issuesd.mf")
      ]
    };
  },
  beforeMount() {},
  computed: {
    overview() {
      if (this.item !== null) {
        return [
          {
            id: 0,
            label: this.$t("MF.summery.bookedon"),
            value: this.item.created_on
          },
          {
            id: 1,
            label: this.$t("MF.summery.appon"),
            value: this.item.date_time
          }
        ];
      } else return null;
    }
  },
  methods: {
    doOpen(item) {
      this.open = true;
      this.item = item;

      this.feedbackForm =
        this.$user.getCurrentUser().role.id === 4
          ? {
              rating: 0,
              comment: "",
              appointment: item.id,
              doctor: item.doctor.id,
              status: "published"
            }
          : {};
      this.$feedback.getItem("app", item.id).then(res => {
        if (res.data.data.length > 0) {
          this.feedback = res.data.data[0];
          console.log("feedback", res.data.data);
        } else this.feedback = "notavailable";
      });
      if (item.type === "video") this.checkAttendence();
      this.$app.getSummery(item.id).then(res => {
        console.log("final res", res);
        if (res !== null) {
          this.summery = res.data.data.changes;
        } else this.summery = "notavailable";
      });
    },
    reportIssue() {
      this.issueDialog = true;
    },
    async sendIssue() {
      this.isreporting = true;
      let list =
        this.$user.getCurrentUser().role.id === 4
          ? this.issueQuickAddPatient
          : this.issueQuickAddDoctor;
      let tosend = {
        issue:
          this.quickissue
            .map(i => {
              return list[i];
            })
            .join() +
          "<br/>" +
          this.issueBody,
        status: "open",
        appointment: this.item.id
      };
      this.$feedback.postIssue(tosend).then(() => {
        this.isreporting = false;
        this.quickissue = [];
        this.issueBody = "";
        this.issueDialog = false;
      });
    },
    async sendFeedback() {
      console.log("feedback", this.feedbackForm);
      this.postingfeedback = true;
      await this.$feedback.postItem(this.feedbackForm).then(res => {
        console.log("result of sending feedback", res);
        res.data.data.owner = this.$user.getCurrentUser();
        this.feedback = res.data.data;
        this.postingfeedback = false;
      });
    },
    async checkAttendence() {
      await this.$app.getlog(this.item.id).whenReady(r => {
        console.log("r", r.get());
        let log = r.get();
        //this.item.log = r.get();

        //this.item.localid = "l" + this.item.id;
        if (Object.keys(log).length > 0) {
          if (
            log.filter(logitem => {
              return (
                logitem.log.includes("doctor") && logitem.log.includes("joined")
              );
            }).length > 0
          )
            this.doctorAttended = 1;
          else this.doctorAttended = -1;
          if (
            log.filter(logitem => {
              return (
                logitem.log.includes("patient") &&
                logitem.log.includes("joined")
              );
            }).length > 0
          )
            this.patientAttended = 1;
          else this.patientAttended = -1;
        } else {
          this.patientAttended = -1;
          this.doctorAttended = -1;
        }
      });
    },
    doClose() {
      this.open = false;
      this.item = null;
      this.doctorAttended = 0;
      this.patientAttended = 0;
      this.summery = null;
      this.feedback = null;
      this.feedbackForm = {
        rating: 0,
        comment: "",
        appointment: null,
        doctor: null
      };
      this.isreporting = false;
      this.quickissue = [];
      this.issueBody = "";
      this.issueDialog = false;
    }
  }
};
</script>
<style scoped>
.theme--light >>> .v-navigation-drawer__border {
  background-color: transparent !important;
}
</style>
